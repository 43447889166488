<template>
  <div id="hfa-services">
    <div class="header" :class="{ 'apply-today': isApplyToday() }">
      <p>
        IN ORDER TO COMPLETE YOUR HFA AFFILIATION APPLICATION PLEASE SELECT THE
        ADMINISTRATION SERVICES HFA CAN PROVIDE ON YOUR BEHALF
      </p>
    </div>

    <div class="body">
      <div class="services">
        <div class="service">
          <h2>US Licensing*</h2>
          <label class="radio" for="usa-licensing">
            <input
              type="checkbox"
              id="usa-licensing"
              v-model="user.usaLicensing"
            />
            <div class="square"></div>
            <span>
              These are the administration services HFA can provide on your
              behalf.
            </span>
          </label>
        </div>
        <div class="service">
          <h2>International Collections*</h2>
          <label class="radio" for="international-collection-01">
            <input
              type="radio"
              id="international-collection-01"
              v-model="user.internationalLicensing"
              value="Y"
            />
            <div class="square"></div>
            <span>
              I authorize HFA to represent me as my licensing agent outside the
              United States pursuant to the Agreement for Representation Outside
              the United States (Addendum 2 to the Representation Agreement).
            </span>
          </label>
          <div class="or">OR</div>
          <label class="radio" for="international-collection-02">
            <input
              type="radio"
              id="international-collection-02"
              v-model="user.internationalLicensing"
              value="N"
            />
            <div class="square"></div>
            <span>
              I do not authorize HFA to represent me outside United States.
            </span>
          </label>
        </div>
        <div class="service">
          <h2>Content ID*</h2>
          <label class="radio" for="content-id-01">
            <input
              type="radio"
              id="content-id-01"
              v-model="user.contentId"
              value="Y"
            />
            <div class="square"></div>
            <span>
              I appoint HFA as my exclusive worldwide Content ID administrator
              pursuant to the Content ID Administration Agreement (Addendum 1 to
              the Representation Agreement).
            </span>
          </label>
          <div class="or">OR</div>
          <label class="radio" for="content-id-02">
            <input
              type="radio"
              id="content-id-02"
              v-model="user.contentId"
              value="N"
            />
            <div class="square"></div>
            <span>
              I do not appoint HFA as my exclusive Content ID administrator.
            </span>
          </label>
        </div>
      </div>
      <div class="not-agree" v-if="isInvalid()">
        <span>Please respond to all questions to continue</span>
      </div>
      <a
        href="http://harryfox.com/content/Publisher_Affiliation_Agreement.pdf"
        target="_blank"
        class="button primary view-agreement"
        >View Agreement</a
      >

      <div class="paypal-container">
        <div
          class="block-paypal"
          v-if="isInvalid() || user.usaLicensing == false"
        ></div>
        <div class="fee">
          Submit and pay $100 <u><b>non-refundable</b></u> affiliation
          application processing fee.
        </div>
        <Paypal
          :usaLicensing="user.usaLicensing"
          :internationalLicensing="user.internationalLicensing"
          :contentId="user.contentId"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Paypal from "@/components/Paypal.vue";

export default {
  name: "HfaServices",
  components: { Paypal },
  data: function() {
    return {
      valid: true,
      user: {
        usaLicensing: null,
        internationalLicensing: null,
        contentId: null
      }
    };
  },
  metaInfo: function() {
    return {
      title: " - Apply Today",
      meta: [
        {
          name: "description",
          content:
            "These are the administration services HFA can provide on your behalf."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  methods: {
    isApplyToday: function() {
      return this.$route.name == "apply-today";
    },
    isInvalid: function() {
      return (
        this.user.usaLicensing == null ||
        this.user.internationalLicensing == null ||
        this.user.contentId == null
      );
    },
    next: function() {
      if (this.isInvalid()) {
        this.valid = false;
        return false;
      }
    }
  },
  mounted: function() {
    const hfaUser = JSON.parse(localStorage.getItem("HfaUser"));

    if (hfaUser == null) {
      this.$toastr.e("You need to create your account first.");
      this.$router.push({ name: "hfa-affiliate" });
    }
    console.log("IS INVALID", this.isInvalid());
  }
};
</script>

<style lang="scss">
#hfa-services {
  // position: relative;

  @media (min-width: $desktop) {
    margin-left: -50px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 200ms ease-in-out;
  }

  .not-agree {
    color: red;
    margin-top: 25px;
    font-weight: bold;
  }
  .fee {
    color: red;
    text-align: center;
    margin-top: 50px;
    margin-bottom: -45px;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .alert-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;

    .alert {
      position: relative;
      background-color: #fff;
      box-shadow: 5px 5px 30px -5px #000;
      border-radius: 3px;
      padding: 0.5em;
      width: 90vw;
      height: 55vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      img {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
      span {
        font-size: 1.4em;
      }
      .button {
        font-size: 1.4em;
        margin-top: 100px;
      }
    }

    @media (min-width: $desktop) {
      .alert {
        width: 50vw;
        height: 30vh;
        padding: 2em;
      }
    }
  }

  .header {
    background-color: $blue;
    color: #fff;
    padding: 0.5em;
    margin: -8px -8px 0;

    p:first-child {
      display: none;
    }
    p {
      text-align: center;
      font-size: 1.4em;
    }
    &.apply-today {
      p {
        display: block;
        font-size: 1.1em;
      }
    }
    .pdf-link {
      color: #fff;
      text-decoration: underline;
      font-size: 0.8em;
      font-family: "Antic Slab", serif;
    }
    .breadcrumbs {
      display: none;

      .item {
        text-align: center;
        margin: 0 10px;
        font-size: 0.8em;

        span {
          display: block;
          margin-bottom: 5px;
        }
        .circle {
          display: inline-block;
          background-color: #fff;
          border-radius: 50%;
          width: 1.5em;
          height: 1.5em;
        }

        &.active {
          .circle {
            background-color: $yellow;
            position: relative;

            .line {
              background-color: $yellow;
              height: 1px;
              width: 40px;
              position: absolute;
              top: 50%;
              transform: translate(-100%, -50%);
            }
          }
        }
      }
    }

    @media (min-width: $desktop) {
      padding: 1em;

      .breadcrumbs {
        float: right;
        display: flex;
      }
    }
  }
  .body {
    padding: 0 0 20px;

    h2 {
      font-family: "Fjalla One", sans-serif;
      font-weight: 400;
    }
    .view-agreement {
      display: block;
      width: 200px;
      font-size: 1.2em;
      margin-top: 20px;
    }
    .services {
      .service {
        .or {
          margin: 10px 0;
        }
      }
    }
    .button.submit {
      display: block;
      width: 200px;
      margin: 50px auto 0;
      font-size: 1.4em;
    }
    .paypal-container {
      position: relative;

      .block-paypal {
        z-index: 9999;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    @media (min-width: $desktop) {
      padding: 50px;
    }
  }
  .radio {
    position: relative;
    font-size: 1.1em;
    cursor: pointer;

    input {
      display: none;
    }
    .square {
      display: inline-block;
      width: 1em;
      height: 1em;
      border: 1px solid;
      vertical-align: middle;
      margin-right: 10px;
      background-color: #fff;
      border-radius: 50%;
    }
    input[type="checkbox"] + .square {
      border-radius: 0;
    }
    input:checked ~ .square {
      background-color: $yellow;
    }
  }
}
</style>
