<template>
  <div class="paypal">
    <div class="paypal-btn" id="paypal-button"></div>
  </div>
</template>
<script>
import paypal from "paypal-checkout";
import axios from "axios";
import ApplicationSuccess from "@/views/ApplicationSuccess.vue";

export default {
  name: "Paypal",
  props: ["usaLicensing", "internationalLicensing", "contentId"],
  methods: {
    updateHash: function() {
      let hash = new Object();
      hash.us_licensing = this._props.usaLicensing ? "Y" : "N";
      hash.international_licensing = this._props.internationalLicensing;
      hash.content_id = this._props.contentId;
      hash.paid = "Y";
      hash.paypal_transaction_id = this._props.paypal_transaction_id;
      hash.paypal_email = this._props.paypal_email;
      return hash;
    },
    showSuccess: function() {
      this.$modal.show(
        ApplicationSuccess,
        { affiliate: true },
        {
          height: "auto",
          width: "80%",
          classes: "v--modal contact-form-modal"
        }
      );
    }
  },
  mounted: function() {
    let $this = this;
    let client = {
      sandbox: process.env.VUE_APP_PAYPAL_SANDBOX_CLIENT,
      production: process.env.VUE_APP_PAYPAL_LIVE_CLIENT
    };

    let payment = (data, actions) => {
      if (
        this._props.usaLicensing == null ||
        this._props.internationalLicensing == null ||
        this._props.contentId == null
      ) {
        return false;
      } else {
        return actions.payment.create({
          transactions: [
            {
              amount: {
                total: "100.00",
                currency: "USD"
              }
            }
          ]
        });
      }
    };

    let tester = this;
    let accountType = { account_type: "affiliate" };

    let onAuthorize = (data, actions) => {
      return actions.payment.execute().then(function(details) {
        tester._props.paypal_transaction_id = details.id;
        tester._props.paypal_email = details.payer.payer_info.email;
        let id = localStorage.user_id;
        axios
          .put(`${process.env.VUE_APP_API_URL}/${id}`, $this.updateHash())
          .then(
            ($this.affiliate = true),
            tester.$router.push({ name: "paypal-redirect" }),
            axios
              .post(
                process.env.VUE_APP_API_CONTACT_URL,
                Object.assign({ email: localStorage.user_email }, accountType)
              )
              .then(function() {}),
            $this.showSuccess(),
            localStorage.removeItem("HfaUser")
          )
          .catch(error => {
            if (error.response) {
              tester.$router.push({ name: "paypal-redirect" });
              window.alert(
                "Your payment was accepted but we didn't get all the information we need to complete your account. Please contact clientservices@harryfox.com for assistance"
              );
            }
          })
          .then(function() {
            // This is always executed after the request
            // console.log("PAYPAL CALLBACK", $this.updateHash());
          });
      });
    };

    paypal.Button.render(
      {
        env: `${process.env.VUE_APP_PAYPAL_ENV}`,
        client,
        locale: "en_US",
        style: {
          size: "large",
          color: "gold",
          shape: "pill",
          fundingicons: true
        },
        commit: true,
        payment,
        onAuthorize
      },
      "#paypal-button"
    );
  }
};
</script>
<style lang="scss">
.paypal-btn {
  text-align: center;
  margin: 50px;
  display: block;
}
</style>
